import Image from 'next/image';
import { Link } from 'next-view-transitions';

import { ContentfulImage } from '@/app/types/contentful/types';


export default function MobileLogo({ logo, closeNav }: { logo: ContentfulImage; closeNav: () => void }) {
  return (
    <Link href={"/"} onClick={closeNav}>
      {logo?.url && (
        <Image
          alt={logo.description || logo.title}
          width={logo.width}
          height={logo.height}
          src={logo.url}
          draggable={false}
          className="cursor-pointer md-lg:w-[70%] md:w-[60%]"
        />
      )}
    </Link>
  );
}
