'use client';

import { GoogleTagManager } from '@next/third-parties/google';

import DriftWidget from '@/app/components/ui/Drift/DriftWidget';

// import { useEffect } from 'react';
// import { useChiliPiper } from './ui/ChiliPiperLoader';

export default function ThirdPartyScripts({ gtmId }: { gtmId: string }) {
  return (
    <>
      {/* // ? Removed Google Tag Manager as we are using Concord CMP to load Google Tag Manager */}
      {/* Analytics scripts - always enabled */}
      <GoogleTagManager gtmId={gtmId} />

      {/* Marketing scripts - always enabled */}
      <DriftWidget />

      {/* Note: Chili Piper is managed by useChiliPiper hook */}
    </>
  );
}
