'use client';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import { NavArrowRight } from 'iconoir-react';
import { AnimatePresence, motion } from 'motion/react';

import LinkComponent from '../../Interactive/Link';
import { itemVariants } from '../animations';

interface SubMenuItem {
  title: string;
  link: string | null;
  dropdown: boolean;
}

interface MenuGroup {
  menuGroupItemCollection: {
    items: SubMenuItem[];
  };
}

interface MobileNavItemProps {
  item: {
    title: string;
    link: string | null;
    dropdown: boolean;
    subNav: {
      menuGroupCollection: {
        items: MenuGroup[];
      };
      specialLink: boolean;
      specialLinkLabel: string;
      specialLinkUrl: string;
    } | null;
    _id: string;
  };
  closeNav: () => void;
  activeDropdown: string | null;
  setActiveDropdown: (id: string | null) => void;
}

const dropdownVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.5
      },
      opacity: {
        duration: 0.2
      }
    }
  },
  visible: {
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        type: "spring",
        stiffness: 300,
        damping: 30
      },
      opacity: {
        duration: 0.2
      },
      staggerChildren: 0.05,
      delayChildren: 0.1
    }
  }
} as const;

const dropdownItemVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30
    }
  }
};

const MobileNavItem: React.FC<MobileNavItemProps> = ({ item, closeNav, activeDropdown, setActiveDropdown }) => {
  const { title, link, dropdown, subNav } = item;
  const isOpen = activeDropdown === item._id;

  const toggleDropdown = () => {
    if (dropdown) {
      setActiveDropdown(isOpen ? null : item._id);
    }
  };

  return (
    <motion.li
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      className={clsx('border-b-[1px] border-WHITE/10 first:border-t-[1px]', isOpen ? 'pb-0 pt-5' : 'py-5')}
    >
      {dropdown ? (
        <button onClick={toggleDropdown} className="flex w-full items-center justify-between px-3">
          <span className="Body-Large-Medium text-white">{title}</span>
          <motion.span animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
            <ChevronDownIcon className="h-4 w-4 text-WHITE" />
          </motion.span>
        </button>
      ) : (
        <a
          href={link || '/'}
          className="block border-none px-3 Body-Large-Medium text-white"
          onClick={() => closeNav()}
        >
          {title}
        </a>
      )}

      {dropdown && subNav && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={dropdownVariants}
              className="overflow-auto font-medium"
            >
              <ul className="mt-5 bg-SLATE_BLUE px-2 py-2">
                {subNav.menuGroupCollection.items.map((menuGroup, groupIndex) => {
                  return (
                    <li key={groupIndex}>
                      <ul>
                        {menuGroup.menuGroupItemCollection.items.map((subItem, itemIndex) => {
                          return (
                            <motion.li
                              variants={dropdownItemVariants}
                              key={`${groupIndex}-${itemIndex}`}
                              className="border-b border-WHITE/10 transition-all duration-300 ease-linear hover:bg-WHITE/20 active:bg-WHITE/50"
                            >
                              <LinkComponent
                                href={subItem.link || '/'}
                                className="block border-none px-2 py-3 text-WHITE"
                                onClick={() => closeNav()}
                              >
                                {subItem.title}
                              </LinkComponent>
                            </motion.li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
                {subNav?.specialLink && (
                  <div className="px-3 pt-4">
                    <LinkComponent
                      href={subNav.specialLinkUrl}
                      onClick={closeNav}
                      className="block rounded-md border-[1px] border-GREEN-500 bg-DEEPGREEN px-4 py-4 text-center font-semibold transition-colors hover:bg-DEEPGREEN-500 text-WHITE"
                    >
                      {subNav.specialLinkLabel}
                      <NavArrowRight className="ml-1 inline-block h-4 w-4" />
                    </LinkComponent>
                  </div>
                )}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </motion.li>

  );
};

export default MobileNavItem;
