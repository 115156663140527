'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import { mobileMenuVariants } from '@/app/components/ui/Header/animations';
import Hamburger from '@/app/components/ui/Header/NavComponents/Hamburger';
import MobileLogo from '@/app/components/ui/Header/NavComponents/MobileLogo';
import MobileNavItem from '@/app/components/ui/Header/NavComponents/MobileNavItem';
import Search from '@/app/components/ui/Search/Search';
import type { ProcessedSearchItem, SortedSearchGroups } from '@/app/types/contentful/types';

interface MobileNavProps {
  content: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  searchIndex: {
    rawItems: ProcessedSearchItem[];
    groupedItems: SortedSearchGroups[];
  };
  hasScrolled: boolean;
}

export default function MobileNav({ content, isOpen, setIsOpen, searchIndex, hasScrolled }: MobileNavProps) {
  const { logo, navItemsCollection, buttonsCollection } = content;
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const closeNav = () => setIsOpen(false);
  const handleToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <>
      <nav
        role="navigation"
        aria-label="Mobile navigation"
        className={clsx(
          'hidden w-full items-center justify-between py-25px text-WHITE md-lg:flex',
          hasScrolled ? 'bg-BLACK' : 'bg-transparent',
          'transition-colors duration-300 ease-in-out',
        )}
      >
        <section className="z-20 flex w-full items-center justify-between px-3 container" role="menubar">
          <MobileLogo logo={logo} closeNav={closeNav} />
          <span className="inline-flex items-center justify-end gap-x-5">
            <Search searchIndex={searchIndex} variant="mobile" />
            <button
              onClick={handleToggle}
              aria-expanded={isOpen}
              aria-controls="mobile-menu"
              aria-label={isOpen ? 'Close menu' : 'Open menu'}
              className="grid justify-items-center gap-1.5"
            >
              <Hamburger isOpen={isOpen} />
            </button>
          </span>
        </section>
      </nav>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="mobile-menu"
            role="dialog"
            aria-modal="true"
            aria-label="Mobile navigation menu"
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenuVariants}
            className="fixed left-0 right-0 top-0 z-[100] flex h-[100dvh] w-full flex-col justify-start overflow-y-scroll bg-BLACK pb-5 pt-6"
          >
            <div className="z-20 flex w-full items-center justify-between px-3">
              <MobileLogo logo={logo} closeNav={closeNav} />
              <button
                onClick={handleToggle}
                aria-expanded={isOpen}
                aria-controls="mobile-menu"
                aria-label={isOpen ? 'Close menu' : 'Open menu'}
                className="grid justify-items-center gap-1.5"
              >
                <Hamburger isOpen={isOpen} />
              </button>
            </div>
            <div>
              <ul className="flex flex-col py-20px">
                {navItemsCollection.items.map((item: any, index: number) => (
                  <MobileNavItem
                    key={index}
                    item={item}
                    closeNav={closeNav}
                    activeDropdown={activeDropdown}
                    setActiveDropdown={setActiveDropdown}
                  />
                ))}
              </ul>
            </div>

            <div className="z-20 mt-10 flex flex-col gap-1 space-y-3 px-3 text-WHITE">
              {buttonsCollection.items.map((button: any, index: number) => (
                <ClientCTAButton key={index} buttonData={button} className="text-center font-semibold" />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
