'use client';

import { useEffect, useRef, useState } from 'react';

import Headband from '@/app/components/ui/Headband/Headband';
import DesktopNav from '@/app/components/ui/Header/Navs/DesktopNav';
import MobileNav from '@/app/components/ui/Header/Navs/MobileNav';

export default function Navbar({ content, searchIndex }: any) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Hide header when scrolling down more than 80px
      if (currentScrollY > lastScrollY.current + 80) {
        setIsHeaderVisible(false);
        lastScrollY.current = currentScrollY;
      }
      // Show header when scrolling up any distance
      else if (currentScrollY < lastScrollY.current) {
        setIsHeaderVisible(true);
        lastScrollY.current = currentScrollY;
      }

      // Add background color after scrolling 25px
      setHasScrolled(currentScrollY >= 25);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 z-50 w-full transition-transform duration-300 ease-in-out ${
        isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
      }`}
      role="banner"
      aria-label="Main header"
    >
      {content.showHeadband && <Headband content={content.headband} />}

      <DesktopNav content={content} searchIndex={searchIndex} hasScrolled={hasScrolled} />
      <MobileNav
        content={content}
        isOpen={mobileMenuOpen}
        setIsOpen={setMobileMenuOpen}
        searchIndex={searchIndex}
        hasScrolled={hasScrolled}
      />
    </header>
  );
}
