'use client';

import { AnimatePresence, motion } from 'motion/react';
import { Link } from 'next-view-transitions';
import { useEffect } from 'react';

import { useNavigationContext } from '@/app/components/Context/navigation.context';
import { getHref } from '@/app/lib/utils/getHref';

interface HeadbandItem {
  title: string;
  ctaLabel: string;
  route: string;
  slug: string;
  externalUrl: string;
}

interface HeadbandProps {
  content: HeadbandItem;
}

export default function Headband({ content }: HeadbandProps) {
  const { isVisible, setIsVisible } = useNavigationContext();
  const { title, ctaLabel, route, slug, externalUrl }: HeadbandItem = content;

  useEffect(() => {
    const headbandClosed = sessionStorage.getItem('headbandClosed');
    if (headbandClosed === 'true') {
      setIsVisible(false);
    }
  }, [setIsVisible]);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('headbandClosed', 'true');
  };

  const headbandVariants = {
    visible: {
      height: '0',
      opacity: 0,
      y: '-100%',
    },
    animate: {
      height: 'auto',
      opacity: 1,
      y: 0,
      transition: {
        height: { duration: 0.3, ease: 'easeOut' },
        opacity: { duration: 0.2, ease: 'easeOut' },
        y: { duration: 0.2, ease: 'easeOut' },
      },
    },
    hidden: {
      height: 0,
      opacity: 0,
      y: '-100%',
      transition: {
        height: { duration: 0.3, ease: 'easeIn' },
        opacity: { duration: 0.2, ease: 'easeIn' },
        y: { duration: 0.2, ease: 'easeIn' },
      },
    },
  };

  return (
    <AnimatePresence mode="sync">
      {isVisible && (
        <motion.div
          role="banner"
          aria-label="Announcement banner"
          className="w-full overflow-clip border-b border-GREEN-500 bg-BLACK text-WHITE"
          initial="visible"
          animate="animate"
          exit="hidden"
          variants={headbandVariants}
          layout
        >
          <div className="container mx-auto flex items-center justify-between px-4 py-2">
            <div className="sm:hidden" />
            <Link href={getHref(route, slug, externalUrl)} aria-label={`Read more about: ${title}`} role="link">
              <div className="group flex cursor-pointer items-center gap-2 rounded-md border border-transparent bg-BLACK-100 px-2 py-1 transition-colors duration-300 hover:border-GREEN-500">
                <p className="text-xs text-GREEN-500 opacity-80 transition-opacity duration-300 group-hover:opacity-100 sm:text-[9px]">
                  {title}
                </p>
                <p className="text-[9px]" aria-hidden="true">
                  •
                </p>
                <p className="text-xs opacity-80 transition-opacity duration-300 group-hover:opacity-100 sm:text-[9px]">{ctaLabel}</p>
              </div>
            </Link>
            <button
              onClick={handleClose}
              className="ml-4 flex h-6 w-6 items-center justify-center rounded-full hover:bg-WHITE/10 sm:h-4 sm:w-4"
              aria-label="Close announcement"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sm:h-4 sm:w-4"
                aria-hidden="true"
                role="img"
              >
                <path
                  d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
