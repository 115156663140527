import clsx from 'clsx';
import React from 'react';

export default function Hamburger({ isOpen }: { isOpen: boolean }) {
  return (
    <div className="grid justify-items-center gap-1.5">
      <span className={clsx('duration-300; h-0.5 w-7 rounded-full bg-WHITE transition', isOpen && 'translate-y-2 rotate-45 h-0.5')} />
      <span className={clsx('duration-300; h-0.5 w-7 rounded-full bg-WHITE transition', isOpen && 'scale-x-0')} />
      <span className={clsx('duration-300; h-0.5 w-7 rounded-full bg-WHITE transition', isOpen && '-translate-y-[6px] -rotate-45 h-0.5')} />
    </div>
  );
}
