'use client';

import clsx from 'clsx';
import { NavArrowDown, NavArrowRight } from 'iconoir-react';
import { AnimatePresence, motion } from 'motion/react';
import Image from 'next/image';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import LinkComponent from '@/app/components/ui/Interactive/Link';
import Search from '@/app/components/ui/Search/Search';
import type {
  ContentfulImage,
  DesktopNavTypes,
  NavItemTypes,
  ProcessedSearchItem,
  SortedSearchGroups,
  SubNavTypes,
} from '@/app/types/contentful/types';

interface DesktopNavProps {
  content: DesktopNavTypes;
  searchIndex: {
    rawItems: ProcessedSearchItem[];
    groupedItems: SortedSearchGroups[];
  };
  hasScrolled: boolean;
}

export default function DesktopNav({ content, searchIndex, hasScrolled }: DesktopNavProps) {
  const { logo, navItemsCollection } = content;

  return (
    <>
      <a
        href="#main-content"
        className="sr-only focus:not-sr-only focus:absolute focus:left-4 focus:top-4 focus:z-50 focus:bg-BLACK focus:px-4 focus:py-2 focus:text-WHITE focus:outline-none focus:ring-2 focus:ring-WHITE/40"
      >
        Skip to main content
      </a>
      <nav
        role="navigation"
        aria-label="Main navigation"
        className={clsx(
          'w-full rounded-b-md md-lg:hidden transition-colors duration-500 ease-in-out',
          hasScrolled ? 'bg-BLACK/40 backdrop-blur-md shadow-md' : 'bg-transparent'
        )}
      >
        <section
          aria-label="Primary navigation"
          className="container top-0 z-50 w-full py-4 text-WHITE transition-all duration-300 ease-out"
        >
          <div className="flex items-center justify-between gap-x-8">
            <Logo logo={logo} />
            <div className="flex-shrink-0">
              <Links navItemsCollection={navItemsCollection} />
            </div>
            <div className="flex min-w-0 flex-[0_1_auto] items-center justify-end gap-x-3">
              <Search searchIndex={searchIndex} variant="desktop" />
              <CTAs content={content} />
            </div>
          </div>
        </section>
      </nav>
    </>
  );
}

function Logo({ logo }: { logo: ContentfulImage }) {
  return (
    <LinkComponent href="/" className="border-none">
      <div className="h-6">
        {logo?.url && (
          <Image
            alt={logo.description || logo.title}
            className="h-full w-full cursor-pointer object-cover transition-opacity duration-300 ease-in-out hover:opacity-75"
            src={logo.url}
            width={64}
            height={64}
            draggable={false}
            priority
          />
        )}
      </div>
    </LinkComponent>
  );
}

function Links({ navItemsCollection }: { navItemsCollection: { items: NavItemTypes[] } }) {
  return (
    <div className="flex items-center text-[calc(1rem-2px)] font-semibold">
      {navItemsCollection.items.map((link: any, index: number) => {
        if (link.dropdown) {
          return (
            <NavLink key={`${link._id}-${index}`} subNavContent={link.subNav}>
              {link.title}
            </NavLink>
          );
        } else {
          return (
            <LinkComponent
              href={link.link}
              key={`${link._id}-${index}`}
              className="rounded-sm border-none px-4 py-2 hover:bg-WHITE/10 hover:text-WHITE"
            >
              {link.title}
            </LinkComponent>
          );
        }
      })}
    </div>
  );
}

function AnimatedDropdown({ showFlyout, subNavContent, id }: { showFlyout: boolean; subNavContent: SubNavTypes; id: string }) {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    setPrefersReducedMotion(mediaQuery.matches);

    const handleChange = (e: MediaQueryListEvent) => {
      setPrefersReducedMotion(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <AnimatePresence>
      {showFlyout && (
        <motion.div
          id={id}
          role="menu"
          tabIndex={-1}
          aria-labelledby={`${id}-button`}
          initial={prefersReducedMotion ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          animate={prefersReducedMotion ? { opacity: 1, y: 0 } : { opacity: 1, y: -5 }}
          exit={prefersReducedMotion ? { opacity: 0, y: 0 } : { opacity: 0, y: -20 }}
          style={{ translateX: '-50%' }}
          transition={{ duration: 0.1, ease: 'easeOut' }}
          className="group-hover:* absolute left-1/2 top-11 rounded-md"
        >
          <div className="absolute -top-1 left-0 right-0 h-1 bg-transparent" />
          <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-transparent" />
          <SubNav subNavContent={subNavContent} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const MemoizedAnimatedDropdown = React.memo(AnimatedDropdown);

function CTAs({ content }: { content: DesktopNavTypes }) {
  return (
    <div className="flex flex-shrink-0 items-center gap-3 text-black">
      {content.buttonsCollection.items.map((button: any, index: number) => (
        <ClientCTAButton key={index} buttonData={button} className="whitespace-nowrap py-1.5" />
      ))}
    </div>
  );
}

function SubNav({ subNavContent }: { subNavContent: SubNavTypes }) {
  const { tag, specialLink, specialLinkLabel, specialLinkUrl, menuGroupCollection } = subNavContent;

  const renderedMenuGroups = useMemo(
    () =>
      menuGroupCollection.items.map((menuGroup: any, groupIndex: number) => (
        <div key={`${menuGroup._id}-${groupIndex}`} className="flex flex-col gap-y-1">
          {menuGroup.menuGroupItemCollection.items.map((menuItem: any, itemIndex: number) => (
            <LinkComponent
              role="menuitem"
              key={`${menuItem._id}-${itemIndex}`}
              href={menuItem.link}
              className={clsx(
                'flex w-full items-center gap-2 rounded-sm border-none px-4 py-2 font-semibold text-WHITE transition-colors duration-200',
                menuItem.link
                  ? 'hover:bg-WHITE/10 hover:text-WHITE focus-visible:bg-WHITE/10 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-WHITE/40'
                  : 'cursor-default select-none opacity-50',
              )}
              tabIndex={menuItem.link ? 0 : -1}
            >
              {menuItem.title}
            </LinkComponent>
          ))}
        </div>
      )),
    [menuGroupCollection],
  );

  const menuRef = useRef<HTMLDivElement>(null);

  // Add keyboard navigation within the menu
  const handleKeyDown = (e: React.KeyboardEvent) => {
    const focusableElements = menuRef.current?.querySelectorAll<HTMLElement>('a[href], button:not([disabled]), [tabindex="0"]');

    if (!focusableElements) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (document.activeElement === lastElement) {
          firstElement.focus();
        } else {
          const currentIndex = Array.from(focusableElements).indexOf(document.activeElement as HTMLElement);
          focusableElements[currentIndex + 1]?.focus();
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (document.activeElement === firstElement) {
          lastElement.focus();
        } else {
          const currentIndex = Array.from(focusableElements).indexOf(document.activeElement as HTMLElement);
          focusableElements[currentIndex - 1]?.focus();
        }
        break;
    }
  };

  return (
    <div
      ref={menuRef}
      onKeyDown={handleKeyDown}
      role="menu"
      tabIndex={-1}
      aria-labelledby={`menu-heading-${subNavContent.tag}`}
      className="h-full w-max rounded-md border-2 border-WHITE/10 bg-SLATE_BLUE text-WHITE shadow-none"
    >
      <p className="select-none pb-3 pl-6 pt-4 text-GREEN-500" id={`menu-heading-${subNavContent.tag}`}>
        {tag}
      </p>
      <div className={clsx('flex gap-x-10 px-2', !specialLink && 'pb-4')} role="group">
        {renderedMenuGroups}
      </div>
      {specialLink && (
        <div className="relative cursor-pointer p-4" role="menuitem">
          <LinkComponent
            href={specialLinkUrl}
            className="block rounded-md border-[1px] border-GREEN-500 bg-DEEPGREEN px-4 py-4 text-center font-semibold transition-colors hover:bg-DEEPGREEN-500"
            aria-label={specialLinkLabel}
          >
            {specialLinkLabel}
            <NavArrowRight className="ml-1 inline-block h-4 w-4" aria-hidden="true" />
          </LinkComponent>
        </div>
      )}
    </div>
  );
}

const NavLink = ({ children, subNavContent }: { children: React.ReactNode; subNavContent: SubNavTypes }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        setOpen(!open);
      } else if (e.key === 'Escape') {
        setOpen(false);
      }
    },
    [open],
  );

  const handleFocusOut = useCallback((e: FocusEvent) => {
    if (navRef.current && !navRef.current.contains(e.relatedTarget as Node)) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    const currentRef = navRef.current;
    currentRef?.addEventListener('focusout', handleFocusOut);
    return () => {
      currentRef?.removeEventListener('focusout', handleFocusOut);
    };
  }, [handleFocusOut]);

  const handleMouseEnter = useCallback(() => setOpen(true), []);
  const handleMouseLeave = useCallback(() => setOpen(false), []);

  return (
    <div ref={navRef} onMouseLeave={handleMouseLeave} className="group relative h-fit w-fit" role="presentation">
      <button
        className="relative cursor-pointer rounded-sm px-4 py-2 transition-all duration-300 ease-out focus:outline-none focus-visible:border-WHITE-950 focus-visible:ring-2 focus-visible:ring-WHITE/40 group-hover:bg-WHITE/10 group-hover:text-WHITE"
        onMouseEnter={handleMouseEnter}
        onKeyDown={handleKeyDown}
        aria-expanded={open}
        aria-haspopup="menu"
        aria-controls={`dropdown-menu-${subNavContent.tag}`}
        aria-label={`${children} menu`}
      >
        {children}
        <NavArrowDown
          className={clsx('ml-1 inline-block h-3 w-3 transition-transform duration-200 ease-in-out', open && 'rotate-180')}
          aria-hidden="true"
        />
      </button>
      <MemoizedAnimatedDropdown showFlyout={open} subNavContent={subNavContent} id={`dropdown-menu-${subNavContent.tag}`} />
    </div>
  );
};
