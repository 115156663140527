'use client';

import React, { createContext, useContext, useState } from 'react';

interface NavigationContextType {
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export function NavigationProvider({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return <NavigationContext.Provider value={{ isVisible, setIsVisible }}>{children}</NavigationContext.Provider>;
}


export function useNavigationContext() {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigationContext must be used within a NavigationProvider');
    }
    return context;
}