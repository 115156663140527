'use client';

import './search.css';

import * as Dialog from '@radix-ui/react-dialog';
import { Command, useCommandState } from 'cmdk';
import { Loader2 as LoaderIcon, Search as SearchIcon } from 'lucide-react';
import { AnimatePresence, motion } from 'motion/react';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

import sanitizeMarkdown from '@/app/lib/utils/sanitizeMarkdown';
import type { GroupedSearchItems, ProcessedSearchItem, SortedSearchGroups } from '@/app/types/contentful/types';
interface SearchProps {
  searchIndex: {
    rawItems?: ProcessedSearchItem[];
    groupedItems: SortedSearchGroups[];
  };
  variant: 'mobile' | 'desktop';
}

export default function Search({ searchIndex, variant }: SearchProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  // Handle keyboard shortcuts
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        const isMobile = window.innerWidth < 768;
        // Check if the user is on a mobile device to prevent double open of search dialog
        if ((variant === 'mobile' && isMobile) || (variant === 'desktop' && !isMobile)) {
          e.preventDefault();
          setOpen(open => !open);
        }
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [variant]);

  useEffect(() => {
    if (!search) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [search]);

  const onSelect = (item: ProcessedSearchItem) => {
    setOpen(false);

    // If external URL is present, navigate using window.location
    if (item.url) {
      window.open(item.url, '_blank');
      return;
    }

    // Otherwise use internal routing
    const path = item.slug ? `/${item.route}/${item.slug}`.replace(/\/+/g, '/') : `/${item.route}`.replace(/\/+/g, '/');

    router.push(path);
  };

  const getDisplayItems = useCallback(
    (_groupName: string, items: GroupedSearchItems) => {
      // When searching, show all items
      if (search) {
        // If the group is Threat Briefings, show 50 items and then the initialPages
        if (_groupName === 'Threat Briefings') {
          return [...items.items.slice(0, 50)];
        }
        return items.items;
      }

      // When not searching, show only initialPages
      return items.initialPages;
    },
    [search],
  );

  return (
    <>
      <DesktopSearch onOpen={setOpen} />
      <MobileSearch onOpen={setOpen} open={open} />
      <AnimatePresence>
        {open && (
          <Dialog.Root open={open} onOpenChange={setOpen} aria-expanded={open} aria-haspopup="dialog">
            <Dialog.Portal>
              <Dialog.Overlay asChild>
                <motion.div
                  role="presentation"
                  className="z-[9999] fixed inset-0 bg-black/60 backdrop-blur-[8px]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                />
              </Dialog.Overlay>

              <Dialog.Content asChild>
                <motion.div
                  role="dialog"
                  aria-label="Search dialog"
                  className="fixed left-1/2 top-[20%] z-[9999] w-[90vw] max-w-[640px] overflow-hidden rounded-[20px] border border-WHITE/50 bg-black/80 text-white backdrop-blur-[8px]"
                  initial={{
                    opacity: 0,
                    x: '-50%',
                    y: -20,
                    scale: 0.5,
                  }}
                  animate={{
                    opacity: 1,
                    x: '-50%',
                    y: 0,
                    scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    x: '-50%',
                    y: -20,
                    scale: 0.5,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: [0.68, -0.55, 0.265, 1.55],
                  }}
                >
                  <Command>
                    <Dialog.Title className="sr-only">Search</Dialog.Title>
                    <Dialog.Description className="sr-only">Search through site content</Dialog.Description>
                    <Command.Input
                      placeholder="Search anything..."
                      value={search}
                      onValueChange={setSearch}
                      role="searchbox"
                      aria-label="Search input"
                    />
                    <Command.List role="listbox">
                      {!loading && <EmptyState />}

                      {loading ? (
                        <Command.Loading>
                          <div className="flex items-center justify-center gap-2">
                            <LoaderIcon className="animate-spin" />
                            <span>Searching...</span>
                          </div>
                        </Command.Loading>
                      ) : (
                        searchIndex.groupedItems.map(({ groupName, items }: { groupName: string; items: GroupedSearchItems }) => {
                          if (!items.initialPages.length && !items.items.length) return null;

                          const displayItems = getDisplayItems(groupName, items);
                          return (
                            <Command.Group key={groupName} heading={groupName}>
                              {displayItems.map((item: ProcessedSearchItem, index: number) => (
                                <Command.Item
                                  key={`${item.route}-${item.slug || ''}-${item.publishedAt || ''}`}
                                  value={item.title + index}
                                  role="menuitem"
                                  keywords={[groupName.toLowerCase()]}
                                  onSelect={() => onSelect(item)}
                                >
                                  {item.title}
                                  {item.description && <span className="text-sm line-clamp-2 text-gray-500">{sanitizeMarkdown(item.description)}</span>}
                                </Command.Item>
                              ))}
                            </Command.Group>
                          );
                        })
                      )}
                    </Command.List>
                  </Command>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )}
      </AnimatePresence>
    </>
  );
}

const EmptyState = () => {
  const search = useCommandState(state => state.search);
  return (
    <Command.Empty>
      <div className="text-sm py-6 text-center">
        <p>No results found for &quot;{search}&quot;</p>
        <p className="mt-2 text-gray-500">Try searching with different keywords</p>
      </div>
    </Command.Empty>
  );
};

function DesktopSearch({ onOpen }: { onOpen: (open: boolean) => void }) {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onOpen(true);
      }
    },
    [onOpen],
  );
  return (
    <div role="search" className="relative w-full min-w-0 max-w-[185px] flex-1 md-lg:hidden" aria-label="Search" aria-controls="search-dialog">
      <SearchIcon className="pointer-events-none absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 text-WHITE/50" aria-hidden="true" />
      <input
        type="text"
        placeholder="Search (⌘K) / (Ctrl+K)"
        className="w-full cursor-pointer rounded-full border border-WHITE/30 bg-BLACK/90 py-[6px] pl-10 pr-4 text-WHITE transition-colors duration-300 placeholder:text-WHITE/50 hover:border-GREEN-500 focus:border-GREEN-500 focus:outline-none placeholder:text-xs"
        onClick={() => onOpen(true)}
        onKeyDown={handleKeyDown}
        aria-label="Search input"
      />
    </div>
  );
}

function MobileSearch({ onOpen, open }: { onOpen: (open: boolean) => void; open: boolean }) {
  return (
    <button
      type="button"
      className="relative hidden min-h-6 min-w-6 rounded-full p-2 transition-colors duration-100 active:bg-GREEN-500/50 md-lg:block"
      onClick={() => onOpen(true)}
      aria-label="Search"
      aria-controls="mobile-menu"
      aria-expanded={open}
      aria-haspopup="dialog"
    >
      <SearchIcon className="pointer-events-none text-WHITE" />
    </button>
  );
}
